import { render, staticRenderFns } from "./editBonCmd.vue?vue&type=template&id=720f2697&scoped=true&"
import script from "./editBonCmd.vue?vue&type=script&lang=js&"
export * from "./editBonCmd.vue?vue&type=script&lang=js&"
import style0 from "./editBonCmd.vue?vue&type=style&index=0&id=720f2697&scoped=true&lang=css&"
import style1 from "./editBonCmd.vue?vue&type=style&index=1&id=720f2697&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720f2697",
  null
  
)

export default component.exports