<template>
    <div v-if="perms.includes('Admin')">
      <div v-if="loading">
         <div id="loading-bg">
         <div class="loading">
           <div class="effect-1 effects"></div>
           <div class="effect-2 effects"></div>
           <div class="effect-3 effects"></div>
         </div>
       </div>
     </div> 
     <section class="invoice-add-wrapper" v-else>
       <b-row class="invoice-add">
         <b-col
           cols="12"
           xl="12"
           md="9"
         >
         <!-- {{rows}} -->
           <b-form @submit.prevent id="formdata">
             <b-card
               no-body
               class="invoice-preview-card"
             >
               <!-- Header -->
               <b-card-body class="invoice-padding pb-0">
   
                 <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
   
                   <!-- Header: Left Content -->
                   <div>
                     <div class="logo-wrapper">
                       <!-- <logo /> -->
                       <h3 class="text-primary invoice-logo">
                         Bon de commande
                       </h3>
                     </div>
                     <!-- <b-card-text class="mb-25">
                       AGADIR 
                     </b-card-text>
                     <b-card-text class="mb-25">
                       Adresse
                     </b-card-text>
                     <b-card-text class="mb-0">
                       +212 0645743797
                     </b-card-text> -->
                   </div>
   
                   <!-- Header: Right Content -->
                   <div class="invoice-number-date mt-md-0 mt-2">
                     <div class="d-flex align-items-center justify-content-md-end mb-1">
                       <h4 class="invoice-title">
                          N° :
                       </h4>
                       <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                         <b-input-group-prepend is-text>
                           <feather-icon icon="HashIcon" />
                         </b-input-group-prepend>
                         <b-form-input
                           id="invoice-data-id"
                           v-model="rows.NumBC"                        
                         />
                       </b-input-group>
                     </div>
                     <div class="d-flex align-items-center justify-content-md-end mb-1">
                       <h4 class="invoice-title">
                         Date:
                       </h4>
                       <b-form-input
                             v-model="rows.DateBC"
                             type="date"
                       />
                       <!-- <flat-pickr
                       format="MM/DD/yyyy"
                         v-model="rows.DateBC"
                         class="form-control invoice-edit-input"
                       /> -->
                       <!-- <b-input-group class="mb-1" style="width: 50%;">
                         <b-form-input
                           
                           id="example-input"
                           v-model="rows.DateBC"
                           type="text"
                           autocomplete="off"
                         />
                         <b-input-group-append>
                           <b-form-datepicker
                             format='dd/mm/yyyy'
                             v-model="rows.DateBC"
                             button-only
                             button-variant="outline-primary"
                             right
                             size="sm"
                             locale="fr"
                             aria-controls="example-input"
                             
                           />
                         </b-input-group-append>
                         </b-input-group> -->
                     </div>
                   </div>
                 </div>
               </b-card-body>
   
   
                <b-card-body class="invoice-padding form-item-section">
                   <div
                       ref="form"
                      
                     >
                       <b-row
                         v-for="(item, index) in rows.Products"
                         :key="index"
                         ref="row"
                         class="pb-2"
                       >
                         <b-col cols="12">
   
                           <div class="d-none d-lg-flex">
                             <b-row class="flex-grow-1 px-1">
                               <!-- Single Item Form Headers -->
                               <b-col
                                 cols="12"
                                 lg="4"
                               >
                                 Produit
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 Prix d'Achat
                               </b-col>
                              
                               <b-col
                                 cols="12"
                                 lg="2"
                               >
                                 Quantité
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 Total
                               </b-col>
                               
                             </b-row>
                             <div class="form-item-action-col" />
                           </div>
   
                           <!-- Form Input Fields OR content inside bordered area  -->
                           <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                           <div class="d-flex border rounded">
                             <b-row class="flex-grow-1 p-2">
                               <!-- Single Item Form Headers -->
                                <b-col
                                 cols="12"
                                 lg="4"
                               >
                                 <label class="d-inline d-lg-none">Produit</label>
                                 <b-form-input
                                   type="text"
                                   class="mb-2"
                                   :value="item.Nom"
                                   disabled
                                 />
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 <label class="d-inline d-lg-none">Prix d'achat</label>
                                 <b-form-input
                                   type="number"
                                   class="mb-2"
                                   :value="item.Prix_Achat"
                                   disabled
                                 />
                               </b-col>
                               
                               <b-col
                                 cols="12"
                                 lg="2"
                               >
                                 <label class="d-inline d-lg-none">Quantité</label>
                                 <b-form-input
                                   type="number"
                                   class="mb-2"
                                   v-model="item.qnt"
                                 />
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 <label class="d-inline d-lg-none">Total</label>
                                 <b-form-input
                                   type="number"
                                   class="mb-2"
                                   disabled
                                   :value="item.tot"
                                 />
                               </b-col>
                               
                              
                             </b-row>
                             <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                               <feather-icon
                                 size="16"
                                 icon="XIcon"
                                 class="cursor-pointer"
                                 @click="removeItemProduct(rows.Products,index)"
                               />
                              
                             </div>
                           </div>
                         </b-col>
                       </b-row>
                   </div><br/>
                    <b-button style="margin-top: 30px;"
                       v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                       size="sm"
                       variant="primary"
                       @click="addNewItemInItemForm" >
                       Ajouter Produit
                     </b-button>
                     <br/><br/>
                   <div
                       ref="form"
                       class="repeater-form"
                       
                     >
                     <!-- :style="{height: trHeight}" -->
                       <b-row
                         v-for="(item, index) in FactureData.produits"
                         :key="index"
                         ref="row"
                         class="pb-2"
                       >
                         <b-col cols="12">
   
                           <div class="d-none d-lg-flex">
                             <b-row class="flex-grow-1 px-1">
                               <!-- Single Item Form Headers -->
                               <b-col
                                 cols="12"
                                 lg="4"
                               >
                                 Produit
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 Prix Achat
                               </b-col>
                               
                               <b-col
                                 cols="12"
                                 lg="2"
                               >
                                 Quantité
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 Total 
                               </b-col>
                               
                             </b-row>
                             <div class="form-item-action-col" />
                           </div>
   
                           <!-- Form Input Fields OR content inside bordered area  -->
                           <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                           <div class="d-flex border rounded">
                             <b-row class="flex-grow-1 p-2">
                               <!-- Single Item Form Headers -->
                               <b-col
                                 cols="12"
                                 lg="4"
                               >
                                 <label class="d-inline d-lg-none">Produit</label>
                                 <!--  v-model="item.Nom" -->
                                 <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                                  
                                   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                   :options="Produits"
                                   label="Nom"
                                   :clearable="false"
                                   class="mb-2 item-selector-title"
                                   :placeholder="item.Nom"
                                   @input="val => updateItemForm(index, val)"
                                 />
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 <label class="d-inline d-lg-none">Prix d'achat</label>
                                 <b-form-input
                                   v-model="item.Prix_Achat"
                                   type="number"
                                   class="mb-2"
                                   disabled
                                 />
                               </b-col>
                              
                               <b-col
                                 cols="12"
                                 lg="2"
                               >
                                 <label class="d-inline d-lg-none">Quantité</label>
                                 <b-form-input
                                   v-model="item.qnt"
                                   type="number"
                                   class="mb-2"
                                   @input="CalcTTC(rows.Products,FactureData.produits)"
                                   :name="'qnty'+index"
                                   :class="error=='qnty'+index ? 'is-invalid':null"
                                 />
                                   
                                 <!-- @change="checkQnty(item.id,item.qnt,'qnty'+index)" -->
                                 <template v-if="error=='qnty'+index">
                                    <small class="text-danger">Quantité de ce produit est insiffisante</small>
                                 </template>
                               </b-col>
                               <b-col
                                 cols="12"
                                 lg="3"
                               >
                                 <label class="d-inline d-lg-none">Total</label>
                                 <b-form-input
                                   type="number"
                                   class="mb-2"
                                   :value="item.Prix_Achat * item.qnt"
                                   disabled
                                 />
                               </b-col>
                              
                             </b-row>
                             <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                               <feather-icon
                                 size="16"
                                 icon="XIcon"
                                 class="cursor-pointer"
                                 @click="removeItem(index)"
                               />
                              
                             </div>
                           </div>
                         </b-col>
                       </b-row>
                   </div>
                    
                   
                   </b-card-body>
               <!-- Items Section -->
            <!-- <br/> <br/> -->
                   
                
               <!-- Invoice Description: Total -->
               <b-card-body class="invoice-padding pb-0">
                 <b-row>
                   <b-col
                     cols="12"
                     md="12"
                     class="mt-md-0 mt-12 d-flex "
                     order="2"
                     order-md="1"
                   >
                   <b-card-body class="invoice-padding pt-0">
                     <span class="font-weight-bold">Note :  </span>
                     <b-form-textarea v-model="rows.note"  />
                   </b-card-body>
                   </b-col>
                 </b-row>
               </b-card-body>
   
               <!-- Spacer -->
               <hr class="invoice-spacing">
               <b-col
                     cols="12"
                     md="12"
                     class="mt-md-0 mt-12 d-flex "
                     order="2"
                     order-md="1">
                   <b-card-body class="invoice-padding pt-0">
                       <b-button
                           v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                           variant="primary"
                           class="mb-75"
                           block
                           @click="addFacture()" > Modifier Le Bon de commande
                       </b-button>
                   </b-card-body>
               </b-col>
              
             </b-card>
           </b-form>
         </b-col>
    
   
       </b-row>
     </section>
     </div>
     <div v-else>
     <not-authoriz></not-authoriz>
   </div>
   </template>
   
   <script>
   import config from '@/config'
   import moment from 'moment';
   import Logo from '@core/layouts/components/Logo.vue'
   import { ref, onUnmounted } from '@vue/composition-api'
   import { heightTransition } from '@core/mixins/ui/transition'
   import Ripple from 'vue-ripple-directive'
   import store from '@/store'
   import router from '@/router'
   import {
     BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
     BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,BTableLite,
     BMedia, BMediaAside, BMediaBody, BLink,BImg,BFormDatepicker, BInputGroupAppend,
   } from 'bootstrap-vue'
   import vSelect from 'vue-select'
   import flatPickr from 'vue-flatpickr-component'
   import NotAuthoriz from '../../NotAuthoriz.vue'
   
   // import invoiceStoreModule from './invoiceStoreModule'
   // import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
   
   export default {
     components: {
       BRow,
       BCol,
       BCard,
       BCardBody,
       BButton,
       BCardText,
       BForm,
       BFormGroup,BTableLite,
       BFormInput,
       BInputGroup,
       BInputGroupPrepend,
       BFormTextarea,
       BFormCheckbox,
       BPopover,
       BMedia, BMediaAside, BMediaBody, BLink,BImg,
       flatPickr,
       vSelect,
       Logo,
       NotAuthoriz,BFormDatepicker, BInputGroupAppend,
       // InvoiceSidebarAddNewCustomer,
     },
     directives: {
       Ripple,
       'b-toggle': VBToggle,
   
     },
     mixins: [heightTransition],
     data(){
         return{
           perms:[],
             loading : false,
             clients:[],
             Produits:[],
             rows:{},
             NumDevis:'',
             DateBC:'',
             note:0,
             TTC:0,
             prod:[],
             newProdc:[],
             error:false,
             UserID:{},
   
   
         }
     },
     mounted() {
        this.perms=JSON.parse(localStorage.getItem('access'));
        //  this.$http.get(`${config.API_BASE_URL}/api/auth/getAllClient`).then(res => { this.clients = res.data });
         // this.getProduct();
         this.loading = true
         this.$http.get(`${config.API_BASE_URL}/api/auth/ShowBc/${router.currentRoute.params.id}`).then(res => { this.rows = res.data,  
         this.$http.post(`${config.API_BASE_URL}/api/auth/getProductFourni/${this.rows.fournisseur_id}`)
         .then(res => { this.Produits = res.data })
         this.loading = false,this.initTrHeight() });
         this.UserID=JSON.parse(localStorage.getItem('userData'))
     },
     created() {
       window.addEventListener('resize', this.initTrHeight);
       
      
     },
     destroyed() {
       window.removeEventListener('resize', this.initTrHeight)
     },
     methods: {
         TwoDigits(val){
               return parseFloat(val).toFixed(2);
         },
         checkQnty(id,qnt,text){
         console.log(id+'/'+qnt+'/'+text)
          this.$http.get(`${config.API_BASE_URL}/api/auth/CheckQnty/${id}/${qnt}`)
               .then(res => {
                     if(res.data.message=='Qnty Insufissante'){
                        this.error=text
                     }else if(res.data.message=='success'){
                       this.error=''
                     }
               })
       },
         removeItemProduct(data,item){
             let idP=data[item].id;
             // console.log(idP)
           this.$swal.fire({
               text: "Vous voulez supprimer ce produit ?",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Oui, supprimer!'
               }).then((result) => {
                if (result.value) {
                   let data2 = new FormData;
                   data.splice(item, 1)
                   let result=[];
                   result=this.rows.Products.concat(this.FactureData.produits);
                    data2.append('products', JSON.stringify(result));
                    data2.append('UserID', this.UserID.id);
                   // console.log(Object.fromEntries(data2))
                     this.$http.post(`${config.API_BASE_URL}/api/auth/destroyProdBc/${router.currentRoute.params.id}/${idP}`,data2)
                       .then(res=> {
                           if(res.data.message=='success'){
                               if(res.data.count >0){
                               this.$swal.fire({
                               position: 'top-end',
                               icon: 'success',
                               title: 'Produit est bien supprimé',
                               showConfirmButton: false,
                               timer: 1500  })
                               
                               }else{
                                    this.$swal.fire({
                                   position: 'top-end',
                                   icon: 'success',
                                   title: 'Produit est bien supprimé',
                                   showConfirmButton: false,
                                   timer: 1500  })
                                   this.$router.push('/bonCommandes');
                               } 
                           }else{
                                this.$swal.fire({
                                   icon: 'error',
                                   title: 'Oops...',
                                   text: 'Vous pouvez pas supprimer ce devis. '
                                   });
                           }
                              
                       })
                 
                   }              
               })
           
         },
         CalcTTC(item1,item2){
             let result=[];
             result=item1.concat(item2);
           this.TTC=0;
           for (let i = 0; i < result.length; i++) {
              this.TTC+=parseFloat((result[i].Prix_Achat*result[i].qnt)*(1+(result[i].tva/100)));
   
               }
           //   console.log(this.TTC)
         },
         
         getProduct(){
         //     this.$http.get(`${config.API_BASE_URL}/api/auth/getProductFourni/${this.rows.fournisseur_id}`)
         // .then(res => { this.Produits = res.data })
         },
         addFacture(){
               let data = new FormData;
             data.append('UserID', this.UserID.id);
             data.append('products', JSON.stringify(this.rows.Products.concat(this.FactureData.produits)));
             data.append('note', this.rows.note);
             data.append('DateBC', this.rows.DateBC);
             // data.append('DateBC', moment(String(this.rows.DateBC)).format('DD/MM/YYYY'));
           //   console.log(Object.fromEntries(data))
             this.$http.post(`${config.API_BASE_URL}/api/auth/EditBc/${router.currentRoute.params.id}`,data)
               .then(res => { 
                   // console.log(res.data)
                   if(res.data=='success'){
                       this.$swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Votre bon de commande est bien modifié',
                     showConfirmButton: false,
                     timer: 1000 })
                   //   document.getElementById("formdata").reset();
                     this.$router.push('/bonCommandes');
                     }else if(res.data.message=='Qnty Insufissante'){
                       this.$swal.fire({
                           icon: 'error',
                           title: 'Quantité Insuffisante',
                           text: 'La quantité du produit '+res.data.Prod+' est insuffisante !' })
                   
                   }else{
                         this.$swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: 'Something went wrong! Pleaz try again'
                 })
                   } })
   
           
           //   AddFactFR
         },
       addNewItemInItemForm() {
         this.$refs.form.style.overflow = 'hidden'
         this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
       this.$nextTick(() => {
           this.trAddHeight(this.$refs.row[0].offsetHeight)
           setTimeout(() => {
             this.$refs.form.style.overflow = null
           }, 350)
         })
         
       },
       removeItem(index) {
         this.FactureData.produits.splice(index, 1)
         this.trTrimHeight(this.$refs.row[0].offsetHeight)
           let result=[];
           result=this.FactureData.produits.concat(this.rows.Products);
           this.TTC=0;
           for (let i = 0; i < result.length; i++) {
              this.TTC+=parseFloat((result[i].Prix_Achat*result[i].qnt)*(1+(result[i].tva/100)));
               }
       },
       initTrHeight() {
         this.trSetHeight(null)
         this.$nextTick(() => {
           this.trSetHeight(this.$refs.form.scrollHeight)
         })
       },
     },
   
     setup() {
       const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
   
       // Register module
       // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
   
       // UnRegister on leave
       onUnmounted(() => {
         if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
       })
   
   
       const itemFormBlankItem = {
         id:0,
         Nom: null,
         Prix_Achat: 0,
         qnt: 0,
         tva:0,
         tot:0,
       }
      
       const FactureData= ref({
           produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
       })
       const invoiceData = ref({
         id: 5037,
         client: null,
   
         // ? Set single Item in form for adding data
         items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
       })
   
       
        const updateItemForm = (index, val) => {
         const { Prix_Achat, id, Nom ,tva} = val
         FactureData.value.produits[index].Prix_Achat = Prix_Achat
         FactureData.value.produits[index].id = id
         FactureData.value.produits[index].Nom = Nom
         FactureData.value.produits[index].tva = tva
       //   this.TTC=this.TwoDigits((Prix_Achat * qnt)*(1+(tva/100)))
       //   FactureData.value.produits[index].total = Prix_Achat*quantite
       
       }
      
   
       // console.log(this.rows.Products)
   
       return {
         FactureData,
         invoiceData,
       //   clients,
         updateItemForm,
         itemFormBlankItem,
       }
     },
   }
   </script>
   
   
   <style scoped>
   .media-aside.align-self-start {
       display: grid;
   }
   .invoice-add .invoice-total-wrapper[data-v-cc5561c2] {
       width: 100%;
       max-width: 18rem;
   }
   </style>
   <style lang="scss" scoped>
   @import "~@core/scss/base/pages/app-invoice.scss";
   @import '~@core/scss/base/components/variables-dark';
   @import '@core/scss/vue/libs/vue-select.scss';
   @import '@core/scss/vue/libs/vue-flatpicker.scss';
   .invoice-add-wrapper {
     .add-new-client-header {
       padding: $options-padding-y $options-padding-x;
         color: $success;
   
       &:hover {
         background-color: rgba($success, 0.12);
       }
     }
   }
   .form-item-section {
   background-color:$product-details-bg;
   }
   
   .form-item-action-col {
     width: 27px;
   }
   
   .repeater-form {
     // overflow: hidden;
     transition: .35s height;
   }
   
   .v-select {
     &.item-selector-title,
     &.payment-selector {
       background-color: #fff;
   
       .dark-layout & {
         background-color: unset;
       }
     }
   }
   
   .dark-layout {
     .form-item-section {
       background-color: $theme-dark-body-bg;
   
       .row .border {
         background-color: $theme-dark-card-bg;
       }
   
     }
   }
   </style>
   